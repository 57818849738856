.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white
}

.loader-container .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-count {
    /* display: block; */
    /* position: fixed; */
    width: 150px;
    /* margin-top: 5rem; */
}